import { Routes } from '@angular/router';
import { MortgagePmtComponent } from './mortgage-pmt/mortgage-pmt.component';
import { HomeComponent } from './home/home.component';
import { EconomicCalendarComponent } from './economic-calendar/economic-calendar.component';
import { EarningsCalendarComponent } from './earnings-calendar/earnings-calendar.component';
import { CovarianceComponent } from './covariance/covariance.component';
import { AboutComponent } from './about/about.component';
import { DdmCalculatorComponent } from './ddm-calculator/ddm-calculator.component';
import { NewsListComponent } from './news-list/news-list.component';
import { StockListResolver } from './resolvers/stock-list.service';
import { AInewsComponent } from './ainews/ainews.component';
import { NewsDetailComponent } from './ainews/news-detail/news-detail.component';
import { LoginComponent } from './login/login.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from './terms-service/terms-service.component';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),  // Lazy load HomeComponent
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'mortgage',
    component: MortgagePmtComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms',
    component: TermsServiceComponent,
  },
  {
    path: 'covariance',
    component: CovarianceComponent,
  },
  {
    path: 'screener',
    loadComponent: () =>
      import('./stock-screener/stock-screener.component').then(
        (m) => m.StockScreenerComponent
      ),
    canActivate: [AuthGuard], // ✅ Protect this route
  },
  // {
  //   path: 'newsRoom',
  //   component: NewsListComponent,
  // },
  {
    path: 'news/:type',
    loadComponent: () => import('./ainews/ainews.component').then(m => m.AInewsComponent),  // Lazy load AInewsComponent
  },
  {
    path: 'newsDetail/:publishedDate/:headline',
    loadComponent: () => import('./ainews/news-detail/news-detail.component').then(m => m.NewsDetailComponent),  // Lazy load NewsDetailComponent
  },
  {
    path: 'portfolio',
    loadComponent: () =>
      import('./portfolio-analysis/portfolio-analysis.component').then((m) => m.PortfolioAnalysisComponent),  
    canActivate: [AuthGuard], // ✅ Protect this route
  },
  {
    path: 'ddm',
    component: DdmCalculatorComponent,
    title: 'DDM-Calculator'
  },
  {
    path: 'financials/chart/:exch/:id',
    loadComponent: () =>
      import('./stock-chart/stock-chart.component').then((m) => m.StockChartComponent),
  },  
  {
    path: 'advanced-chart/:exch/:id',
    loadComponent: () =>
      import('./advanced-chart/advanced-chart.component').then((m) => m.AdvancedChartComponent),
  },  
  {
    path: 'economic-calendar',
    loadComponent: () => import('./economic-calendar/economic-calendar.component').then(m => m.EconomicCalendarComponent),  // Lazy load EconomicCalendarComponent
  },
  {
    path: 'earnings-calendar',
    loadComponent: () => import('./earnings-calendar/earnings-calendar.component').then(m => m.EarningsCalendarComponent),  // Lazy load EarningsCalendarComponent
  },
  {
    path: 'map/:index',
    loadComponent: () =>
      import('../heat-map/heat-map.component').then((m) => m.HeatMapComponent),
  },
  {
    path: 'actives/:type',
    loadComponent: () =>
      import('./stock-list/stock-list.component').then((m) => m.StockListComponent),
    resolve: {
      stockData: StockListResolver,
    }
  },
  {
    path: 'lambda',
    loadComponent: () =>
      import('./chat-bot/chat-bot.component').then((m) => m.ChatBotComponent),
    canActivate: [AuthGuard], // ✅ Protect this route
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  { path: 'about/', redirectTo: 'about', pathMatch: 'full' },
];
