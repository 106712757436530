import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {  RouterModule } from '@angular/router';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ NavigationBarComponent, RouterModule],
  templateUrl: 'app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {


  title = 'homes';
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
}



