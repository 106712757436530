<main>
  <section class="content">
    <app-navigation-bar></app-navigation-bar>
    <router-outlet style="margin-top: 3em; overflow-x:hidden;"></router-outlet>
    
    <ng-template #ssrLoading>
      <div class="loading-screen">Loading...</div>
    </ng-template>
    
  </section>
</main>

