import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.authService.getGoogleToken();

    console.log(token);
    if (token) {
      return true; // User is authenticated, allow access
    } else {
      this.authService.setRedirectUrl(state.url); // Save the URL they were trying to access
      this.router.navigate(['/login']); // Redirect to login
      return false; // Block access
    }
  }
}
