<header class="background">
  <span class="lambda">Lambda Finance</span>
  <nav class="desktop-nav">
    <ul class="nav-list">
      <li><a routerLink="">Home</a></li>
      <li class="dropdown">
        <a>Heat Maps</a>
        <div class="dropdown-content">
          <a style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="map/{{'sp500'}}">S&P 500</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em; " routerLink="map/{{'ndq'}}">Nadaq 100</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em; " routerLink="map/{{'dow'}}">Dow Jones Industrial Average</a>
        </div>
      </li>
      <li class="dropdown">
        <a>News Room</a>
        <div class="dropdown-content">
          <a 
            style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" 
            [routerLink]="['/news/Economy']" 
            [queryParams]="{ page: 1 }">
            Economic
          </a>
          <a 
            style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" 
            [routerLink]="['/news/Crypto']" 
            [queryParams]="{ page: 1 }">
            Crypto
          </a>
          <a 
            style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" 
            [routerLink]="['/news/Commodities']" 
            [queryParams]="{ page: 1 }">
            Commodities
          </a>
          <a 
            style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" 
            [routerLink]="['/news/Forex']" 
            [queryParams]="{ page: 1 }">
            Forex
          </a>
          <a 
            style="display: inline-block; padding-top: 0.25em; padding-bottom: 0.25em;" 
            [routerLink]="['/news/Stock']" 
            [queryParams]="{ page: 1 }">
            Stock
          </a>
        </div>
      </li>
      <li class="dropdown">
        <a>Markets</a>
        <div class="dropdown-content">
          <div class="dropdown-content">
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'most'}}">Most Actives</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'gainers'}}">Biggest Winners</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'losers'}}">Biggest Losers</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'cloudCrusaders'}}">Cloud Crusaders</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'dividendStocks'}}">Dividends Forever</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'evCharging'}}">EV Charging Superstars</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'pharmaPowerhouses'}}">Pharma Powerhouses</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'digitalPayments'}}">Digital Payments Dynamos</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'waterWarriors'}}">Water Warriors</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'skyRevolution'}}">Sky Revolution</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'farmFutures'}}">Farm Futures</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'nasdaq100'}}">Nasdaq 100</a>
            <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/actives/{{'sp1500'}}">S&P 1500</a>
          </div>          
        </div>
      </li>
      <!--<li class="dropdown">
        <a>Sectors</a>
        <div class="dropdown-content" style="width: 12em;">
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Communication Services</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Consumer Discretionary</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Consumer Staples</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Energy</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Financials</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Health Care</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Industrials</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Information Technology</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Real Estate</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/">Utilities</a>
        </div>
      </li>-->
      <li class="dropdown" (click)="toggleDropdown($event)">
        <a>Market Tools</a>
        <div class="dropdown-content" style="width: 14em;">
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/portfolio" (click)="toggleMenu()">Portfolio</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/screener" (click)="toggleMenu()">Stock Screener</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/mortgage" (click)="toggleMenu()">Mortgage Payment Schedule</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/covariance" (click)="toggleMenu()">Covariance</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/ddm" (click)="toggleMenu()">DDM Calculator</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/economic-calendar" (click)="toggleMenu()">Economic Calendar</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/earnings-calendar" (click)="toggleMenu()">Earnings Calendar</a>
          <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/" (click)="toggleMenu()">Dividend Returns</a>
        </div>
      </li>
      <li><a routerLink="/login" (click)="toggleMenu()">Login</a></li>
      <li><a routerLink="/lambda" (click)="toggleMenu()">&lambda; Chat</a></li>
      <li><a routerLink="/about" (click)="toggleMenu()">About Us</a></li>
      <!-- Add more navigation links as needed -->
    </ul>
    <div class="search-container">
      <div class="search-box">
        <input id='textBox' type="text"
               #searchInputRef
               placeholder="Search Stocks, Indexes & more"
               class="search-input"
               typeahead="canidate for canidate in filteredCandidates"
               (input)="onInputKeyDown($event)"
               (blur)="onInputBlur()"
               (keydown.enter)="search(); onInputBlur()"/>
        <button class="search-button" (click)="search(); onInputBlur()">Search</button>
      </div>
      <div id='dpdwn' [ngClass]="!isInputFocused || filteredCandidates.length === 0 ? 'displayEmpty' : 'dropdown2-content' ">
        <ul style="display: flex; flex-direction: column; list-style-type: none; padding-left: 0; width: 100%; overflow-y: auto; overflow-x: hidden;">
          <li *ngFor="let candidate of filteredCandidates"
              style="border-bottom: 0.05em solid #ddd; padding-bottom: 0.5em; padding-top: 0.5em; transition: background-color 0.3s; width: 100%; display:block; text-align:left; overflow-y:hidden;"
              (mouseenter)="hoveredItem = candidate"
              (mouseleave)="hoveredItem = null"
              [ngStyle]="{ 'background-color': hoveredItem === candidate ? '#ccebff' : 'transparent' }"
              (click)="search(candidate)">
            <span style="margin-left:1em;">{{ candidate.symbol }}</span>
            <span style="margin-left:1em;">{{ candidate.price | currency }}</span>
            <div style="font-size: 0.7em; margin-left: 1em;">{{ candidate.name }}</div>
          </li>
        </ul>
      </div>
    </div>


  </nav>
  <button class="hamburger" (click)="toggleMenu()">☰</button>
  <div class="search-container-mobile">
    <div class="search-box">
      <input id='textBox-mobile' type="text"
             #searchInputRef
             placeholder="Search Stocks, Indexes & more"
             class="search-input"
             typeahead="canidate for canidate in filteredCandidates"
             (input)="onInputKeyDown($event)"
             (blur)="onInputBlur()"
             (keydown.enter)="search(); onInputBlur()"/>
      <button class="search-button" (click)="search(); onInputBlur()">Search</button>
    </div>
    <div id='dpdwn' [ngClass]="!isInputFocused || filteredCandidates.length === 0 ? 'displayEmpty' : 'dropdown2-content' ">
      <ul style="display: flex; flex-direction: column; list-style-type: none; padding-left: 0; width: 100%; overflow-y: auto; overflow-x: hidden; position: absolute;">
        <li *ngFor="let candidate of filteredCandidates"
            style="border-bottom: 0.05em solid #ddd; padding-bottom: 0.5em; padding-top: 0.5em; transition: background-color 0.3s; width: 100%; display:block; text-align:left; overflow-y:auto;"
            (mouseenter)="hoveredItem = candidate"
            (mouseleave)="hoveredItem = null"
            [ngStyle]="{ 'background-color': hoveredItem === candidate ? '#ccebff' : 'transparent' }"
            (click)="search(candidate)">
          <span style="margin-left:1em;">{{ candidate.symbol }}</span>
          <span style="margin-left:1em;">{{ candidate.price | currency }}</span>
          <div style="font-size: 0.7em; margin-left: 1em;">{{ candidate.name }}</div>
        </li>
      </ul>
    </div>
  </div>
  <!-- Mobile Hamburger Menu -->
  
</header>

<nav class="mobile-sidebar" [class.active]="isMenuOpen">
  <ul class="nav-list">
    <li><a routerLink="" (click)="toggleMenu()">Home</a></li>
    <li class="dropdown">
      <a (click)="toggleDropdown($event)">Heat Maps</a>
      <div class="dropdown-content">
        <a routerLink="map/{{'sp500'}}" (click)="toggleMenu()">S&P 500</a>
        <a routerLink="map/{{'ndq'}}" (click)="toggleMenu()">Nasdaq 100</a>
        <a routerLink="map/{{'dow'}}" (click)="toggleMenu()">Dow Jones Industrial Average</a>
      </div>
    </li>
    <li class="dropdown">
      <a (click)="toggleDropdown($event)">News Room</a>
      <div class="dropdown-content">
        <a 
          [routerLink]="['/news/Economy']" 
          [queryParams]="{ page: 1 }" 
          (click)="toggleMenu()">
          Economic
        </a>
        <a 
          [routerLink]="['/news/Crypto']" 
          [queryParams]="{ page: 1 }" 
          (click)="toggleMenu()">
          Crypto
        </a>
        <a 
          [routerLink]="['/news/Forex']" 
          [queryParams]="{ page: 1 }" 
          (click)="toggleMenu()">
          Forex
        </a>
        <a 
          [routerLink]="['/news/Commodities']" 
          [queryParams]="{ page: 1 }" 
          (click)="toggleMenu()">
          Commodities
        </a>
        <a 
          [routerLink]="['/news/Stock']" 
          [queryParams]="{ page: 1 }" 
          (click)="toggleMenu()">
          Stock
        </a>
      </div>
    </li>
    <li class="dropdown">
      <a (click)="toggleDropdown($event)">Markets</a>
      <div class="dropdown-content">
        <a routerLink="/actives/{{'most'}}" (click)="toggleMenu()">Most Actives</a>
        <a routerLink="/actives/{{'gainers'}}" (click)="toggleMenu()">Biggest Winners</a>
        <a routerLink="/actives/{{'losers'}}" (click)="toggleMenu()">Biggest Losers</a>
        <a routerLink="/actives/{{'cloudCrusaders'}}" (click)="toggleMenu()">Cloud Crusaders</a>
        <a routerLink="/actives/{{'dividendStocks'}}" (click)="toggleMenu()">Dividends Forever</a>
        <a routerLink="/actives/{{'evCharging'}}" (click)="toggleMenu()">EV Charging Superstars</a>
        <a routerLink="/actives/{{'pharmaPowerhouses'}}" (click)="toggleMenu()">Pharma Powerhouses</a>
        <a routerLink="/actives/{{'digitalPayments'}}" (click)="toggleMenu()">Digital Payments Dynamos</a>
        <a routerLink="/actives/{{'waterWarriors'}}" (click)="toggleMenu()">Water Warriors</a>
        <a routerLink="/actives/{{'skyRevolution'}}" (click)="toggleMenu()">Sky Revolution</a>
        <a routerLink="/actives/{{'farmFutures'}}" (click)="toggleMenu()">Farm Futures</a>
        <a routerLink="/actives/{{'nasdaq100'}}" (click)="toggleMenu()">Nasdaq 100</a>
        <a routerLink="/actives/{{'sp1500'}}" (click)="toggleMenu()">S&P 1500</a>
      </div>
    </li>
    <li class="dropdown">
      <a>Market Tools</a>
      <div class="dropdown-content">
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/portfolio" (click)="toggleMenu()">Portfolio</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/screener" (click)="toggleMenu()">Stock Screener</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/mortgage" (click)="toggleMenu()">Mortgage Payment Schedule</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/covariance" (click)="toggleMenu()">Covariance</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/ddm" (click)="toggleMenu()">DDM Calculator</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/economic-calendar" (click)="toggleMenu()">Economic Calendar</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/earnings-calendar" (click)="toggleMenu()">Earnings Calendar</a>
        <a style="display: block; padding-top: 0.25em; padding-bottom: 0.25em;" routerLink="/" (click)="toggleMenu()">Dividend Returns</a>
      </div>
    </li>
    <li><a routerLink="/login" (click)="toggleMenu()">Login</a></li>
    <li><a routerLink="/lambda" (click)="toggleMenu()">&lambda; Chat</a></li>
    <li><a routerLink="/about" (click)="toggleMenu()">About Us</a></li>
  </ul>
</nav>




