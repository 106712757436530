import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { StockDataService } from '../stock-data.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StockListResolver implements Resolve<any> {

  constructor(    private stockDataService: StockDataService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    // Define all stock groups

    // Stock groups data fetch
    const type = route.params['type']; // Get the type from the route params
    // Fetch data for gainers
    if (type === 'gainers') {
      this.stockDataService.fetchDataWithCache('gainers', () => this.stockDataService.gainers()).subscribe(data => {
        this.stockDataService.gainers$.next(data);
      });
      return true;
    }

    // Fetch data for losers
    if (type === 'losers') {
      this.stockDataService.fetchDataWithCache('losers', () => this.stockDataService.losers()).subscribe(data => {
        this.stockDataService.losers$.next(data);
      });
      return true;
    }

    // Fetch data for most active
    if (type === 'most') {
      this.stockDataService.fetchDataWithCache('mostActive', () => this.stockDataService.mostActive()).subscribe(data => {
        this.stockDataService.actives$.next(data);
      });
      return true;
    }


    // Stock groups data fetch
    const data = await this.stockDataService.getStockLists(type).toPromise();
    //const data:any = [];
    
    return data.length > 0 ? data.stockData: false;
  }
}
