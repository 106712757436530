import { ApplicationConfig, makeStateKey, TransferState } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideOAuthClient } from 'angular-oauth2-oidc';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),  // ✅ Added hydration support
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideOAuthClient(),
    provideAnimationsAsync()  // ✅ Removed duplicates
  ]
};