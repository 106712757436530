<div class="login-container">
    <h2>Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <label for="email">Email</label>
      <input id="email" type="email" formControlName="email" required>
      <div *ngIf="loginForm.controls.email.invalid && loginForm.controls.email.touched">
        <small>Email is required and must be valid.</small>
      </div>
  
      <label for="password">Password</label>
      <input id="password" type="password" formControlName="password" required>
      <div *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
        <small>Password must be at least 6 characters.</small>
      </div>
  
      <button type="submit" [disabled]="loginForm.invalid">Login</button>
    </form>
  
    <div class="google-signin">
      <button class="google-button" (click)="signInWithGoogle()">
        <img src="assets/google_logo_icon.jpg" alt="Google Logo" class="google-logo" />
        Sign in with Google
      </button>
    </div>    
  </div>
  