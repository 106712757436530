import { Component, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import 'chartjs-adapter-date-fns';
import { StockDataService } from '../stock-data.service';
import { CommonModule } from '@angular/common';
import { TickerTapeComponent } from '../ticker-tape/ticker-tape.component';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-navigation-bar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.scss'
})
export class NavigationBarComponent {
  @ViewChild('searchInputRef') searchInputRef!: ElementRef;
  newsData: any[] = [];
  ticker: string = ''
  constructor(private stockDataService: StockDataService, private router: Router, private eRef: ElementRef) {
  }

  stockCandidates: any = [];
  cryptoCandidates: any = [];
  forexCandidates: any = [];
  candidates: any = [];
  filteredCandidates: any = [];
  path: string = '';
  isInputFocused: boolean = false;
  hoveredItem: any = null;
  isMenuOpen = false;
  dataInitialized = false;
  
  ngOnInit(): void {

  }
  

  onInputFocus() {
  
    this.isInputFocused = true;
    
    // Only load data on first focus
    if (!this.dataInitialized) {
      forkJoin({
        stockCandidates: this.stockDataService.stockList(),
        cryptoCandidates: this.stockDataService.cryptoList(),
        forexCandidates: this.stockDataService.forexList(),
      }).subscribe({
        next: ({ stockCandidates, cryptoCandidates, forexCandidates }) => {
          this.stockCandidates = stockCandidates;
          this.cryptoCandidates = cryptoCandidates;
          this.forexCandidates = forexCandidates;
          this.candidates = [
            ...this.stockCandidates,
            ...this.cryptoCandidates,
            ...this.forexCandidates,
          ];
          this.dataInitialized = true;
        },
        error: (error: any) => {
          console.error('Error loading candidates', error);
        },
      });
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  // Listen for clicks outside the sidebar
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (this.isMenuOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;  // Close the menu if clicked outside
    }
  }

  onInputBlur() {
    setTimeout(() => {
      this.isInputFocused = false;
    }, 200); // Delay in milliseconds
  }

  onInputKeyDown(event: Event) {
    const inputValue = (event.target as HTMLInputElement)?.value || '';
  
    // If data is not initialized, wait for it to load before filtering
    if (!this.dataInitialized) {
      console.log('Data not initialized, waiting to load...');
      this.onInputFocus(); // Trigger data loading
      const interval = setInterval(() => {
        if (this.dataInitialized) {
          clearInterval(interval);
          console.log('Data loaded. Proceeding to filter candidates...');
          this.onSearchWithInputValue(inputValue);
        }
      }, 100); // Check every 100ms if data is initialized
    } else {
      // If data is already initialized, directly filter candidates
      this.isInputFocused = true;
      this.onSearchWithInputValue(inputValue);
    }
  }

  onSearchWithInputValue(inputValue: string) {


    this.ticker = inputValue;
    this.filteredCandidates = this.candidates
      // First, sort by the length of the ticker
      .sort((a:any, b:any) => a.symbol.length - b.symbol.length)
      // Then filter by symbol or companyName
      .filter((candidate: any) => {
        const inputUpper = inputValue.toUpperCase();
        return (
          (!candidate.symbol.includes('.') && (candidate.symbol.toUpperCase().startsWith(inputUpper)) ||
          candidate.name?.toUpperCase().includes(inputUpper))
        );
      })
      // Limit the result to the top 5 candidates
      .slice(0, 5);
    if (this.filteredCandidates) {
      let height = this.filteredCandidates.length * 3.1; // Assuming each element has a height of 30px

      // Apply the height to the div element
      let dpdwnElement = document.getElementById("dpdwn");

      // Apply the height to the div element if it exists
      if (dpdwnElement) {
        dpdwnElement.style.height = height + "em";
      }
    }
    
    // Further logic using inputValue
  }

  search(candidate?: any) {
    // Implement logic to perform search action (e.g., API call)
    // This function can be called when the user clicks the Search button
    let foundObject = this.candidates.find((obj: any) => obj.symbol === this.ticker.toUpperCase());

    if (candidate && candidate.symbol) {
      this.router.navigate(['financials','chart', candidate.exchangeShortName, candidate.symbol ]);
      (this.searchInputRef.nativeElement as HTMLInputElement).value = '';
      (this.searchInputRef.nativeElement as HTMLInputElement).blur();
      this.filteredCandidates = [];
    } else if (this.ticker && foundObject) {
      this.router.navigate(['financials','chart', foundObject.exchangeShortName, this.ticker ]);
      // Ensure the input box is cleared
      const searchInput = this.searchInputRef.nativeElement as HTMLInputElement;
      if (searchInput) {
          searchInput.value = ''; // Clear the input value
          searchInput.blur(); // Remove focus from the input
      } else {
          console.error('Search input not found');
      }
      this.filteredCandidates = [];

    }
  }

  toggleDropdown(event: Event) {
    const dropdownContent = (event.target as HTMLElement).nextElementSibling as HTMLElement;
    if (dropdownContent) {
      dropdownContent.style.display = dropdownContent.style.display === 'block' ? 'none' : 'block';
    }
  }

}
